import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Dropdown from "../../components/Dropdown";
import style from "../../Styles.module.scss";
import RegisterTask from "./RegisterTask";
import {Jobs} from "../jobs/Jobs";
import {WarningMessage} from "../../components/small-cute-components";

const bStyle = {
    display: 'block'
};

const hrStyle = {
    marginTop: '.25rem'
};


const sectionHeaderStyle = {
    display: 'block',
    marginTop: '1.5rem',
};

const imageContainer = {
    display: 'block',
    margin: '2rem 0',
};

const imgStyle = {
    width: '100%'
};


const unitAmounts = [
    {id: 0, name: '0', value: 0},
    {id: 1, name: '0,5', value: 0.5},
    {id: 2, name: '1,0', value: 1},
    {id: 3, name: '1,5', value: 1.5},
    {id: 4, name: '2,0', value: 2},
    {id: 5, name: '2,5', value: 2.5},
    {id: 6, name: '3,0', value: 3},
    {id: 7, name: '3,5', value: 3.5},
    {id: 8, name: '4,0', value: 4},
    {id: 9, name: '5', value: 5},
    {id: 10, name: '6', value: 6},
    {id: 11, name: '7', value: 7},
    {id: 12, name: '8', value: 8},
    {id: 13, name: '9', value: 9},
    {id: 14, name: '10', value: 10},
    {id: 15, name: '11', value: 11},
    {id: 16, name: '12', value: 12},
    {id: 17, name: '13', value: 13},
    {id: 18, name: '14', value: 14},
    {id: 19, name: '15', value: 15},
    {id: 20, name: '16', value: 16},
];

class TasksUI extends Component {

    state = {
        selectedTaskType: null,
        selectedAmount: null
    };

    onTaskTypeSelected = (selected) => {
        this.setState({selectedTaskType: selected});
    };
    onUnitAmountSelected = (selected) => {
        this.setState({selectedAmount: selected});
    };

    render() {
        const {selectedTaskType, selectedAmount} = this.state;
        const {
            tasks,
            routeId,
            assignmentId,
            assignmentAddress,
            assignmentDescription,
            performedByTime,
            maximumAllowedSnowDepth,
            fileLinks
        } = this.props;

        return (
            <div className={style.Tasks}>
                <h4 style={{marginBottom: '1rem', wordBreak: 'break-word'}}>{assignmentAddress}</h4>

                <b style={bStyle}>Jobbregistrering</b>
                <hr style={hrStyle}/>

                {tasks.length === 0 ? this.getNoTasksForRegistrationHtml(tasks, selectedTaskType, selectedAmount, routeId, assignmentId) : ""}
                {tasks.length > 0 ? this.getJobRegistrationHtml(tasks, selectedTaskType, selectedAmount, routeId, assignmentId) : ""}

                <b style={sectionHeaderStyle}>Arbeidsbeskrivelse</b>
                <hr style={hrStyle}/>
                {!!performedByTime && (<p>{`Ryddes innen kl. ${performedByTime}`}</p>)}
                {!!maximumAllowedSnowDepth && (<p>{`Maksimum tillatt snødybde: ${maximumAllowedSnowDepth}`}</p>)}
                <p>{assignmentDescription}</p>

                <b style={sectionHeaderStyle}>Situasjonskart</b>
                <hr style={hrStyle}/>

                {fileLinks.map(img => (
                    <div key={img.fileName} style={imageContainer}>
                        <a href={img.url}>
                            <img style={imgStyle} src={img.url} alt={img.fileName}/>
                            <p>{img.fileName}</p>
                        </a>
                    </div>
                ))}

                <b style={sectionHeaderStyle}>Siste registrerte jobber</b>
                <hr style={hrStyle}/>

                <Jobs assignmentId={assignmentId}/>

            </div>
        );
    }

    getNoTasksForRegistrationHtml(tasks, selectedTaskType, selectedAmount, routeId, assignmentId) {
        return <WarningMessage msg="Ingen oppgavetyper funnet for manuell registrering."/>;
    }

    getJobRegistrationHtml(tasks, selectedTaskType, selectedAmount, routeId, assignmentId) {
        return <>
            <div className={style.taskRegistration}>

                <Dropdown title={`Oppgavetype`} items={tasks} onItemSelected={this.onTaskTypeSelected}
                          style={{
                              flexBasis: '100%',
                              marginLeft: 'auto',
                              textAlign: 'left',
                              marginTop: '5px'
                          }}/>

                <Dropdown title={`Antall`} items={unitAmounts}
                          style={{
                              marginTop: '1.2rem',
                              flexBasis: '100%',
                              marginLeft: 'auto',
                              textAlign: 'left',
                          }}
                          onItemSelected={this.onUnitAmountSelected}
                />

                <RegisterTask
                    taskId={selectedTaskType}
                    numberOfHours={selectedAmount}
                    unitAmount={selectedAmount}
                    routeId={routeId}
                    assignmentId={assignmentId}/>

            </div>
        </>;
    }
}

TasksUI.propTypes = {
    tasks: PropTypes.array.isRequired,
    routeId: PropTypes.number.isRequired,
    assignmentId: PropTypes.number.isRequired,
    assignmentAddress: PropTypes.string.isRequired,
    assignmentDescription: PropTypes.string,
    maximumAllowedSnowDepth: PropTypes.string,
    performedByTime: PropTypes.string,
    fileLinks: PropTypes.array.isRequired

    // TODO PLEASE STOP THIS PROPS DRILLING!!
};

export default TasksUI;
