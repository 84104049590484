import React from 'react';
import PropTypes from 'prop-types';
import {goFetch, makeCancelable} from "../../utils/GoFetch";
import {withUserContext} from "../../components/UserContext";
import AssignmentListItemUI from "./AssignmentListItemUI";
import RequestComponent from "../../components/RequestComponent";
import {VISIT_ACTION_UNDO_LAST} from "../../components/AdvancedVisit";
import {parseAssignment} from "./Assignments";

class AssignmentListItem extends RequestComponent {

    state = {
        isLoading: false,
        errorMsg: '',
        assignment: this.props.assignment,
    };

    onPlaceVisit = (visitAction, visitType) => {
        const { assignment } = this.state;
        const {auth, routeId, onVisitButtonClicked} = this.props;
        
        const endpoint = visitAction === VISIT_ACTION_UNDO_LAST ? 'unregister-visit' : 'register-visit';

        onVisitButtonClicked();

        this.setState({isLoading: true, errorMsg: ''}, () => {
            this.request = makeCancelable(
                goFetch(`/api/v2/assignments/${endpoint}`, auth.token, 'POST', {
                    routeId: routeId,
                    assignmentId: assignment.id,
                    visitType,
                    visitAction
                })
            );

            this.request.promise.then(
                json => this.onFinish(parseAssignment(json)),
                error => this.onHandleError(error)
            );
        });
    };

    onFinish = (assignment) => {
        this.setState({
            isLoading: false,
            errorMsg: '',
            assignment
        });
        
        this.props.onVisitUpdated(assignment);
    };

    onHandleError = (e) => {
        this.setState({
            isLoading: false,
            errorMsg: e.errorMsg
        });
    };

    render() {
        const { onListItemClicked } = this.props;
        const { isLoading, errorMsg, assignment } = this.state;
        
        return (
            <AssignmentListItemUI address={assignment.address}
                                  lastVisited={assignment.previouslyVisitedAt}
                                  visits={assignment.visits}
                                  hasFinished={assignment.finished}
                                  onPlaceVisit={this.onPlaceVisit}
                                  isLoading={isLoading}
                                  errorMsg={errorMsg}
                                  onComponentClicked={() => onListItemClicked(assignment.id)}/>
        );
    }
}

AssignmentListItem.propTypes = {
    routeId: PropTypes.string.isRequired,
    assignment: PropTypes.object.isRequired,
    onListItemClicked: PropTypes.func.isRequired,
    onVisitUpdated: PropTypes.func.isRequired,
    onVisitButtonClicked: PropTypes.func.isRequired
};

export default withUserContext(AssignmentListItem);
