import React, {useState} from 'react';
import PropTypes from 'prop-types';
import style from "../Styles.module.scss";
import clsx from "clsx";

export const VISIT_ACTION_NOT_FINISHED = 'NOT_FINISHED';
export const VISIT_ACTION_FINISHED = 'FINISHED';
export const VISIT_ACTION_UNDO_LAST = 'UNDO_LAST';

export const VISIT_TYPE_HALF = 'HALF';
export const VISIT_TYPE_ONE = 'ONE';
export const VISIT_TYPE_DOUBLE = 'DOUBLE';

const AdvancedVisit = props => {

    const [state, setState] = useState({visitType: null});
    
    const isSelected = type => state.visitType === type ? null : style.AdvancedVisitRadioUnselected;
    
    const onSelect = type => event => setState({visitType: type});
    
    const onAction = event => {
        props.onAction(event.target.name, state.visitType)
    };
    

    return (
        <div className={style.Modal} onClick={(evt) => {
            evt.preventDefault();
            evt.stopPropagation();
            props.onDismiss();
        }}>
            <div className={clsx(style.ModalInner, style.AdvancedVisit)} onClick={(evt) => {
                evt.stopPropagation();
            }}>

                <h4>{props.title}</h4>

                <div className={style.AdvancedVisitRadioGroup}>
                    <h6>Velg besøksform</h6>
                    <button type="button" onClick={onSelect(VISIT_TYPE_HALF)} className={clsx(isSelected(VISIT_TYPE_HALF), style.Label, style.LabelYellow)}>Halvt</button>
                    <button type="button" onClick={onSelect(VISIT_TYPE_ONE)} className={clsx(isSelected(VISIT_TYPE_ONE), style.Label, style.LabelGreen)}>Helt</button>
                </div>

                <hr/>

                <div className={style.AdvancedVisitButtonGroup}>
                    <button type="button" name={VISIT_ACTION_NOT_FINISHED} onClick={onAction} className={`btn btn-lg btn-warning`}>Ikke ferdig</button>
                    <button type="button" name={VISIT_ACTION_FINISHED} onClick={onAction} className={`btn btn-lg btn-success`}>Ferdig</button>

                    {props.canUnregister && <React.Fragment>
                        <hr/>
                        <button type="button" name={VISIT_ACTION_UNDO_LAST} onClick={onAction} className={`btn btn-lg btn-danger`}>Angre forrige besøk</button>
                    </React.Fragment>}
                </div>

            </div>
        </div>);
};

AdvancedVisit.propTypes = {
    title: PropTypes.string.isRequired,
    canUnregister: PropTypes.bool.isRequired,
    onDismiss: PropTypes.func.isRequired,
    onAction: PropTypes.func.isRequired,
};

export default AdvancedVisit;
