import React, {Component} from 'react';
import PropTypes from 'prop-types';
import styles from "../../Styles.module.scss";
import {ErrorMessage, Loading, Subheading, WarningMessage} from "../../components/small-cute-components";
import AssignmentListItem from "./AssignmentListItem";
import FinishRoute from "./FinishRoute";
import Breadcrumb from "../../components/Breadcrumb";
import {makeUnique} from "../../utils/Utils";

class AssignmentsUI extends Component {

    state = {
        assignments: [],
        canFinishRoute: false,
        errorMsg: null
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.assignments !== this.props.assignments) {
            this.setState({
                assignments: this.props.assignments,
                canFinishRoute: this.canFinishRoute(this.props.assignments),
                errorMsg: this.props.errorMsg
            });
        }
    }

    render() {
        const {assignments, canFinishRoute, errorMsg} = this.state;
        const {routeId, isLoading, onListItemClicked, onVisitButtonClicked, match} = this.props;

        return (
            <div className="user-select--none">
                <Breadcrumb match={match}/>

                <ErrorMessage msg={errorMsg}/>

                {isLoading ? <Loading/> :

                    assignments.length > 0 ?
                        <ul className={`${styles.listItems} list-group`}>
                            <Subheading>{assignments[0].routeName}</Subheading>
                            {assignments.map(a =>
                                <AssignmentListItem key={makeUnique(a.id)}
                                                    routeId={routeId}
                                                    assignment={a}
                                                    onListItemClicked={onListItemClicked}
                                                    onVisitUpdated={this.onVisitUpdated}
                                                    onVisitButtonClicked={onVisitButtonClicked}
                                />
                            )}
                            <FinishRoute canFinish={canFinishRoute} routeId={routeId} assignments={assignments}/>
                        </ul> : <WarningMessage msg="Fant ingen steder på valgt rute."/>
                }
            </div>
        );
    }

    onVisitUpdated = (assignment) => {
        this.setState(prevState => {
            const updatedAssignments = [...prevState.assignments];
            let ix = updatedAssignments.findIndex(a => a.id === assignment.id);
            updatedAssignments[ix] = assignment;
            return {
                assignments: updatedAssignments,
                canFinishRoute: this.canFinishRoute(updatedAssignments)
            };
        });
    };

    canFinishRoute = assignments => {
        let anyFinished = false;
        assignments.forEach(a => {
            if (a.finished) {
                anyFinished = true;
            }
        });
        return anyFinished;
    };
}

AssignmentsUI.propTypes = {
    routeId: PropTypes.string.isRequired,
    isLoading: PropTypes.bool.isRequired,
    errorMsg: PropTypes.string,
    assignments: PropTypes.array.isRequired,
    onListItemClicked: PropTypes.func.isRequired,
    onVisitButtonClicked: PropTypes.func.isRequired
};

export default AssignmentsUI;
