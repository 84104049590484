import React, {Component} from 'react';
import {deleteCookie, getCookie, saveCookie} from "../utils/CookieUtil";
import {Redirect, Route} from "react-router";
import {withCookies} from "react-cookie";

export const UserContext = React.createContext();

const COOKIE_NAME = 'c_snoserv';
const emptyUser = {auth: {token: '', rToken: '', ttl: 0}, user: {id: '', fullName: ''}};

class UserProvider extends Component {

    state = getCookie(this.props.cookies, COOKIE_NAME, emptyUser);
    
    clearUser = () => {
        console.log("Clearing user cookie: " + COOKIE_NAME);
        deleteCookie(this.props.cookies, COOKIE_NAME);
        this.setState(emptyUser);
    };

    setUser = (token, rToken, ttl, userId, fullName) => {
        let cookie = {
            auth: {
                token: token,
                rToken: rToken,
                ttl: ttl
            },
            user: {
                id: userId,
                fullName: fullName
            }
        };

        saveCookie(this.props.cookies, COOKIE_NAME, cookie, ttl); 

        this.setState(cookie);
    };
    
    render() {
        return (
            <UserContext.Provider value={
                { setUser: this.setUser, clearUser: this.clearUser, ...this.state }
            }>
                {this.props.children}
            </UserContext.Provider>
        );
    }
}

export const ProtectedRoute = ({ component: Component, ...routeProps }) => (
    <UserContext.Consumer>
        {({ auth }) => (
            <Route {...routeProps} render={props =>
                auth.token.length > 0 ? <Component {...props} /> : <Redirect to={`/login`} />}
            />
        )}
    </UserContext.Consumer>
);

// TODO split into smaller more concrete wrappers? e.g. withSimpleUserContext (isAuthenticated)
export function withUserContext(WrappedComponent) {
    class UserContextHOC extends React.Component {
        render() {
            const { forwardedRef, ...rest } = this.props;
            return (
                <UserContext.Consumer>
                    {({ setUser, clearUser, auth, user }) => (
                        <WrappedComponent
                            setUser={setUser}
                            clearUser={clearUser}
                            auth={auth}
                            user={user}
                            isAuthenticated={auth.token.length > 0}
                            ref={forwardedRef}
                            {...rest} />
                    )}
                </UserContext.Consumer>
            );
        }
    }
    
    return React.forwardRef((props, ref) => {
        return <UserContextHOC {...props} forwardedRef={ref} />
    });
}

export default withCookies(UserProvider);
