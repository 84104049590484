import PropTypes from "prop-types";
import React from "react";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles({
    root: {
        display: 'flex',
        marginTop: 20,
        overflowX: 'hide',
        fontSize: '0.6rem'
    },
    table: {
        minWidth: 340,
        fontSize: '0.6rem'
    },
    tableHeader: {
        overflowWrap: 'break-word',
        padding: '0 2px 0 2px',
        fontWeight: 'bold',
        fontSize: '0.7rem',
        textAlign: 'left'
    },
    tableCell: {
        overflowWrap: 'break-word',
        padding: '0 8px 0 2px',
        fontSize: '0.7rem',
        textAlign: 'left'
    },

});

const JobList = ({jobs}) => {
    const classes = useStyles();

    return (
        <TableContainer component={Paper} style={{overflowX: 'hidden'}}>
            <Table className={classes.table} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell style={{width: '40px'}} className={classes.tableHeader}>Dato</TableCell>
                        <TableCell style={{width: '60px'}} className={classes.tableHeader}>Sjåfør</TableCell>
                        <TableCell style={{width: '3px'}} className={classes.tableHeader}>Ant</TableCell>
                        <TableCell style={{width: '50px'}} className={classes.tableHeader}>Type</TableCell>
                        <TableCell className={classes.tableHeader}>Oppgave</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {jobs.map((job) => (
                        <TableRow key={job.id}>
                            <TableCell className={classes.tableCell} component="th" scope="row">
                                {job.performedDate}
                            </TableCell>
                            <TableCell className={classes.tableCell}>{job.performedByUser.firstName}</TableCell>
                            <TableCell className={classes.tableCell}>{job.unitAmount}</TableCell>
                            <TableCell className={classes.tableCell}>{job.priceType}</TableCell>
                            <TableCell className={classes.tableCell}>{job.task.taskType.jobType}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );

};

JobList.propTypes = {
    jobs: PropTypes.array
};

export default JobList;
