import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {LoadingBtn} from "../../components/small-cute-components";
import RequestExecutor from "../../components/RequestExecutor";
import * as ReactDOM from "react-dom";
import Modal from "../../components/Modal";

class FinishRoute extends React.Component {
    
    state = { showWarningMsg: false };

    constructor(props) {
        super(props);
        this.executorRef = React.createRef();
    }
    
    onClick = () => {
        const { canFinish, assignments } = this.props;
        
        const showWarning = canFinish && this.numberOfUnvisited(assignments) > 0;
        if (showWarning) {
            this.setState({showWarningMsg: showWarning});
        }
        else {
            this.onFinishRoute();
        }
    };

    onFinishRoute = () => {
        const { routeId } = this.props;
        
        this.executorRef.current.execute(`/api/v2/routes/${routeId}/finish-trip`, 'PUT', null, () => {
            window.location.reload(); // I know, i know...
        });
    };

    render() {
        const { showWarningMsg } = this.state;
        const { canFinish } = this.props;

        return (
            <Fragment>
                {showWarningMsg && this.warningMessage()}

                <RequestExecutor ref={this.executorRef}>
                    {isLoading => {
                        return <LoadingBtn
                            isLoading={isLoading}
                            disabled={!canFinish}
                            onClick={this.onClick} className={`list-group-item list-group-item-action`}>Ferdigstill rute</LoadingBtn>
                    }}
                </RequestExecutor>
            </Fragment>
        );
    }

    warningMessage = () => {
        const { assignments } = this.props;
        const numberOfUnvisited = this.numberOfUnvisited(assignments);
        const places = numberOfUnvisited === 1 ? 'plass' : 'plasser';
        
        return ReactDOM.createPortal(
            <Modal title={"Advarsel"}
                   text={`Det er ${numberOfUnvisited} ${places} du enda ikke har besøkt. Ønsker du fremdeles å ferdigstille rute?`}
                   acceptBtnTitle="Ferdigstill"
                   closeBtnTitle="Avbryt"
                   onModalClosed={() => {
                       this.setState({showWarningMsg: false});
                   }} 
                   onModalAccepted={() => {
                       this.setState({showWarningMsg: false}, this.onFinishRoute);
                   }}
            />,
            document.getElementById('app-modal')
        );
    };

    numberOfUnvisited(assignments) {
        let count = 0;
        assignments.forEach(a => {
            if (!a.finished) {
                count++;
            }
        });
        return count;
    };
}

FinishRoute.propTypes = {
    canFinish: PropTypes.bool.isRequired,
    routeId: PropTypes.string.isRequired,
    assignments: PropTypes.array.isRequired
};

export default FinishRoute;
