import React, {Component} from 'react';
import PropTypes from 'prop-types';
import styles from "../Styles.module.scss";
import {Link} from "react-router-dom";

// DEV-NOTE Do not use at root-level! Expects path at least be /something

class Breadcrumb extends Component {
    
    render() {
        const { match } = this.props;
        const data = match.url.split('/').slice(1); // TODO split and wash for empty elements 
        
        return (
            <nav className={styles.breadcrumbContainer} aria-label="breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to="/">Hjem</Link></li>
                    {data.map((p, ix) => {
                        return <li key={ix} className={this.classForIx(data, ix)}><Link to={this.pathForIx(data, ix)}>{this.nameForIx(data, ix)}</Link></li>
                    })}
                </ol>
            </nav>
        );
    }
    
    classForIx(data, ix) {
        return ix === data.length-1 ? 'breadcrumb-item active' : 'breadcrumb-item';
    }
    
    pathForIx(data, ix) {
        let path = '';
        let i;
        for(i = 0; i < ix+1; i++) {
            path += '/' + data[i];
        }
        return path;
    }
    
    nameForIx(data, ix) {
        switch (ix) {
            case 0:
                return data[0] === 'myroutes' ? 'Mine ruter' : 'Alle ruter';
            case 1:
                return 'Plasser';
            case 2:
                return 'Detaljer';
            default:
                return 'Ukjent verdi';
        }
    }
}

Breadcrumb.propTypes = {
    match: PropTypes.object.isRequired
};

export default Breadcrumb;
