import React, {Fragment} from 'react';
import RequestComponent from "./RequestComponent";
import {withUserContext} from "./UserContext";
import {goFetch, makeCancelable} from "../utils/GoFetch";
import * as ReactDOM from "react-dom";
import Modal from "./Modal";

const modalNode = document.getElementById('app-modal');

class RequestExecutor extends RequestComponent {

    state = { isLoading: false, response: null, error: null };

    execute = (url, method, body, onSuccess = null, onError = null) => {
        const { auth } = this.props;
        
        this.setState({isLoading: true, response: null, error: null}, () => {
            this.request = makeCancelable(
                goFetch(url, auth.token, method, body)
            );
            
            this.request.promise.then(json => {
                this.setState({isLoading: false, response: json, error: null}, () => {
                    onSuccess && onSuccess(this.state.response);
                });
            }, error => {
                this.setState({isLoading: false, response: null, error: error}, () => {
                    onError && onError(this.state.error);
                });
            });
        });
    };

    render() {
        const { isLoading, error } = this.state;

        return (
            <Fragment>
                {error && ReactDOM.createPortal(<Modal title={`Feilmelding (${error.code})`} text={error.errorMsg} />, modalNode)}
                {this.props.children(isLoading)}
            </Fragment>
        );
    }
}

export default withUserContext(RequestExecutor);
