import {name, type, version} from "../VERSION";

export async function goFetch(url, token, method = null, body = null) {
    const opts = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'X-App': name,
            'X-Version': `${version}-${type}`,
            'Authorization': "Bearer " + token
        }
    };
    if (method) {
        opts.method = method;
    }
    if (body) {
        opts.method = 'POST';
        opts.body = JSON.stringify(body);
    }
    
    let response = null;
    try {
        response = await fetch(url, opts);
    } catch (e) {
        // E.g network related errors
        console.error(`Got network related error from fetch`);
        console.error(e);
        return Promise.reject({
            code: 0,
            url: url,
            errorMsg: 'Nettverksfeil. Ingen kontakt med baksiden.'
        });
    }
    
    let result = {code: response.status, url: url};
    try {
        let responseBody = await response.text();
        if (responseBody) {
            result.jsonBody = JSON.parse(responseBody);
        }
    } catch (e) {
        // E.g backend returns something like 500 with HTML body 
        console.error(`Failed parsing body as json on ${opts.method} request '${url}'. Request did return http status code ${result.code}.`);
    }
    
    if (!response.ok) {
        result.errorMsg = 'En ukjent feil oppstod. Vennligst kontakt administrasjonen.';
        
        if (result.code === 401) {
            result.errorMsg = 'Det ser ut til at din sesjon har utløpt. Vennligst logg av og på igjen.';
        } else if (result.jsonBody) {
            result.errorMsg = !!result.jsonBody.localizedMessage ? result.jsonBody.localizedMessage : result.jsonBody.responseMessage;
        }
    }
    
    return new Promise((resolve, reject) => {
        if (response.ok) {
            resolve(result.jsonBody);
        } else {
            reject(result);
        }
    });
}

// A bit of creds to https://github.com/facebook/react/issues/5465#issuecomment-157888325
export const makeCancelable = (promise) => {
    let hasNotCancelled = true;

    const wrappedPromise = new Promise((resolve, reject) => {
        promise.then(
            val => hasNotCancelled && resolve(val),
            error => hasNotCancelled && reject(error)
        );
    });

    return {
        promise: wrappedPromise,
        cancel() {
            hasNotCancelled = false;
        },
    };
};
