import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {Link} from "react-router-dom";
import './routes.scss';
import StartRoute from "./StartRoute";
import clsx from "clsx";

const RouteListItem = ({path, route}) => {
    const { id, owner, name, startedAt, displayText } = route;
    const [state, setState] = useState({startedAt});
    
    const onRouteStarted = date => {
        setState({routeStarted: true});
    };

    const routeIsStarted = !!state.routeStarted || (!!route.startedAt && !route.finishedAt)
    const canStartRoute = !routeIsStarted && route.hasAssignments;
    return (
        <Link className={clsx('list-group-item', 'list-group-item-action', routeIsStarted && 'route-ongoing')} to={path}>
            <p className="margin_bottom--smallest">{owner}</p>
            
            <div className="title-action-container">
                <h4>{name}</h4>
                <StartRoute routeId={id} canStartRoute={canStartRoute} onRouteStarted={onRouteStarted} />
            </div>
            
            <div>{displayText}</div>
        </Link>
    );
};

RouteListItem.propTypes = {
    path: PropTypes.string.isRequired,
    route: PropTypes.object.isRequired
};

export default RouteListItem;
