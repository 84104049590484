

export function getCookie(cookies, key, fallback) {
    let cookie = cookies.get(key);
    return !!cookie ? cookie : fallback;
    
}

export function saveCookie(cookies, key, cookie, maxAge) {
    cookies.set(key, cookie, {
        path: '/',
        maxAge: maxAge
    });
}

export function deleteCookie(cookies, key) {
    cookies.remove(key);
    console.log("Removed cookie: " + key);
}
