import React, {useRef} from 'react';
import PropTypes from 'prop-types';
import RequestExecutor from "../../components/RequestExecutor";
import {LoadingBtn} from "../../components/small-cute-components";
import clsx from "clsx";

const StartRoute = ({routeId, canStartRoute, onRouteStarted}) => {

    const reqExecutorRef = useRef(null);

    const onStartRoute = e => {
        e.preventDefault();
        e.stopPropagation();
        
        reqExecutorRef.current.execute(`/api/v2/routes/${routeId}/start-trip`, 'POST', null, response => {
            !!onRouteStarted && onRouteStarted(response.startedDate);
        });
    };
    
    const btnTitle = 'Start rute';
    const btnStyleType = canStartRoute ? 'btn-outline-success' : 'btn-outline-secondary';
    return (
        <RequestExecutor ref={reqExecutorRef}>
            { isLoading => <LoadingBtn isLoading={isLoading} disabled={!canStartRoute} className={clsx('btn', 'btn-lg', btnStyleType)} onClick={onStartRoute}>{btnTitle}</LoadingBtn> }
        </RequestExecutor>
    );
};

StartRoute.propTypes = {
    routeId: PropTypes.number.isRequired,
    canStartRoute: PropTypes.bool.isRequired,
    onRouteStarted: PropTypes.func
};

export default StartRoute;
