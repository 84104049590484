import React from 'react';
import PropTypes from 'prop-types';
import styles from '../../Styles.module.scss';
import {ErrorMessage, Loading} from "../../components/small-cute-components";

class LoginUI extends React.Component {

    state = {
        username: '',
        password: ''
    };

    onChange = (evt) => {
        this.setState({
            [evt.target.name]: evt.target.value
        });
    };
    
    performLogin = (evt) => {
        evt.preventDefault();
        
        const { performLogin } = this.props;
        const { username, password } = this.state;
        
        performLogin(username, password);
    };

    render() {
        const { username, password } = this.state;
        const { isLoading, errorMsg, resetPassword } = this.props;

        return (
            <div className={styles.Login}>

                <h3>Velkommen til Snøservice</h3>
                <h5>Vennligst logg inn</h5>
                
                <ErrorMessage msg={errorMsg} style={{maxWidth: '32rem', marginLeft: 'auto', marginRight: 'auto'}} />
                
                { isLoading && <Loading/> }
                
                { !isLoading && (
                    <form onSubmit={this.performLogin}>
                        <input className={styles.textField}
                               type="text"
                               placeholder="Brukernavn"
                               name="username"
                               value={username}
                               onChange={this.onChange}/>

                        <input className={styles.textField}
                               type="password"
                               placeholder="Passord"
                               name="password"
                               value={password}
                               onChange={this.onChange}/>

                        <input className={styles.btnLogin}
                               type="submit"
                               value="Logg inn"/>

                        <input className={styles.btnForgottenPw}
                               type="button"
                               value="Glemt passord?"
                               onClick={resetPassword}/>
                    </form>
                )}
            </div>
        );
    }
}

LoginUI.propTypes = {
    isLoading: PropTypes.bool.isRequired,
    errorMsg: PropTypes.string,
    performLogin: PropTypes.func.isRequired,
    resetPassword: PropTypes.func.isRequired
};

export default LoginUI;
