import React from 'react';
import PropTypes from 'prop-types';
import {withUserContext} from "./UserContext";
import {Loading, WarningMessage} from "./small-cute-components";
import {goFetch, makeCancelable} from "../utils/GoFetch";
import RequestComponent from "./RequestComponent";
import {PAGE_REFRESH_INTERVAL_IN_MS} from "../constants";

class Async extends RequestComponent {

    intervalID;

    state = {
        isLoading: false,
        result: null,
        error: null,
    };

    // Currently a one-off request component! componentDidUpdate(prevProps, prevState) to listen for url-changes
    componentDidMount() {
        this.fetchData();
    }

    componentWillUnmount() {
        /*
          stop getData() from continuing to run even
          after unmounting this component. Notice we are calling
          'clearTimeout()` here rather than `clearInterval()` as
          in the previous example.
        */
        clearTimeout(this.intervalID);
    }

    fetchData = () => {
        const {auth, url, postBody} = this.props;
        this.setState({isLoading: true}, () => {
            this.request = makeCancelable(
                goFetch(url, auth.token, 'GET', postBody)
            );

            this.request.promise.then(
                json => this.onResult(json),
                error => this.onError(error)
            );
        });
    }

    onResult = (json) => {
        this.setState({
            isLoading: false,
            result: json,
            error: null
        });

        if (this.props.refreshInterval) {
            console.log("refreshing routes: ", this.props);
            this.intervalID = setTimeout(this.fetchData.bind(this), PAGE_REFRESH_INTERVAL_IN_MS);
        }
    };

    onError = (error) => {
        let isReAuth = error.code === 401;
        if (isReAuth) {
            this.props.clearUser();
            return;
        }

        this.setState({
            isLoading: false,
            result: null,
            error: error
        });
    };

    render() {
        const {isLoading, result, error} = this.state;

        if (isLoading) {
            return (<Loading/>);
        }
        if (error) {
            return (
                <div>
                    <WarningMessage style={{margin: '1rem 1rem 0 1rem'}} msg={error.errorMsg}/>
                    {this.props.render({isLoading, result, error})}
                </div>);
        }
        return this.props.render({isLoading, result, error})
    }
}

Async.propTypes = {
    url: PropTypes.string.isRequired,
    render: PropTypes.func.isRequired,
    postBody: PropTypes.object,
    refreshInterval: PropTypes.number
};

export default withUserContext(Async);
