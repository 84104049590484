import React from 'react';
import PropTypes from 'prop-types';
import style from "../../Styles.module.scss";
import {Loading} from "../../components/small-cute-components";
import RequestExecutor from "../../components/RequestExecutor";

class RegisterTask extends React.Component {

    constructor(props) {
        super(props);
        this.executorRef = React.createRef();
    }
    
    onRegisterTask = (e) => {
        e.preventDefault();
        e.stopPropagation();

        const { taskId, routeId, unitAmount, assignmentId } = this.props;

        this.executorRef.current.execute('/api/v2/assignments/register-visit', 'POST', {
            routeId: routeId,
            assignmentId: assignmentId,
            taskId: taskId.id,
            numberOfHoursUsed: unitAmount.value
        });
    };
    
    
    render() {
        const { taskId, unitAmount } = this.props;
        const buttonIsEnabled = !!taskId && !!unitAmount;
        
        return (
            <div className={style.registerBtn}>
                <RequestExecutor ref={this.executorRef}>
                    {(isLoading) => {
                        return isLoading ? <Loading style={{display: 'inline-block', position: 'relative', top: '4px', width: '30px', height: '30px', margin: '0', borderWidth: '.15em'}} /> :
                            <button className="btn btn-outline-success" disabled={!buttonIsEnabled} onClick={this.onRegisterTask}>Registrer</button>
                    }}
                </RequestExecutor>
            </div>
        );
    }
}

RegisterTask.propTypes = {
    taskId: PropTypes.object,
    unitAmount: PropTypes.object,
    // TODO replace this props drilling hell with something like context as data store through hooks!
    routeId: PropTypes.number.isRequired,
    assignmentId: PropTypes.number.isRequired
};

export default RegisterTask;
