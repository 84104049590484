import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter} from "react-router-dom";
import {CookiesProvider} from "react-cookie";
import './index.scss';
import * as serviceWorker from './serviceWorker';
import UserProvider from "./components/UserContext";
import App from "./App";
import * as moment from 'moment';
import 'moment/locale/nb';

class Root extends React.Component {
    
    render() {
        moment().locale('nb');
        
        return (
            <BrowserRouter basename="/app">

                <CookiesProvider>

                    <UserProvider>

                        <App/>

                    </UserProvider>
                    
                </CookiesProvider>

            </BrowserRouter>
        );
    }
}

ReactDOM.render(<Root/>, document.getElementById('app-root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
