import {useEffect} from "react";
import ReactDOM from 'react-dom';

const modalRoot = document.getElementById('app-modal');

const Modal = ({children}) => {

    const node = document.createElement('div');

    useEffect(() => {
        modalRoot.appendChild(node);
        return () => modalRoot.removeChild(node);
    }, [node]);

    return ReactDOM.createPortal(
        children,
        node
    );
};

export default Modal;