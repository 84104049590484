import React from 'react';
import Routes from "./Routes";
import {withUserContext} from "../../components/UserContext";

const MyRoutes = props => {
    return (
        <Routes config={{urlToRoutes: `/api/v2/users/${props.user.id}/routes?offset=0&limit=100`}} {...props} />
    );
};

export default withUserContext(MyRoutes);
