import React from 'react';
import {withUserContext} from "../../components/UserContext";
import RequestComponent from "../../components/RequestComponent";
import {goFetch, makeCancelable} from "../../utils/GoFetch";
import AssignmentsUI from "./AssignmentsUI";
import {PAGE_REFRESH_INTERVAL_IN_MS} from "../../constants";

class Assignments extends RequestComponent {

    intervalID;

    state = {
        isLoading: false,
        assignments: [],
        errorMsg: null
    };

    componentDidMount() {
        this.fetchData();
    }

    componentWillUnmount() {
        /*
          stop getData() from continuing to run even
          after unmounting this component. Notice we are calling
          'clearTimeout()` here rather than `clearInterval()` as
          in the previous example.
        */
        console.log("Clearing timeout for id: " + this.intervalID);
        clearTimeout(this.intervalID);
    }

    fetchData = () => {
        const {match, auth} = this.props;
        this.setState({isLoading: true}, () => {
            this.request = makeCancelable(
                goFetch(`/api/v2/routes/${match.params.routeId}/assignments?offset=0&limit=50`, auth.token)
            );

            this.request.promise.then(
                json => {
                    this.setState({
                        isLoading: false,
                        assignments: parseAssignments(json),
                        errorMsg: null
                    }, () => {
                        this.restoreScrollPosition();
                        console.log(`refreshing assignments view every ${PAGE_REFRESH_INTERVAL_IN_MS} ms`);
                        this.intervalID = setTimeout(this.fetchData.bind(this), PAGE_REFRESH_INTERVAL_IN_MS);
                    });
                },
                error => {
                    this.setState({
                        isLoading: false,
                        assignments: [],
                        errorMsg: error.errorMsg
                    })
                }
            );
        });
    }

    render() {
        const {match} = this.props;
        const {isLoading, assignments, errorMsg} = this.state;

        return <AssignmentsUI routeId={match.params.routeId}
                              isLoading={isLoading}
                              assignments={assignments}
                              errorMsg={errorMsg}
                              onListItemClicked={this.onListItemClicked}
                              onVisitButtonClicked={this.onVisitButtonClicked}
                              {...this.props}
        />
    }

    onListItemClicked = (assignmentId) => {
        const {match, history} = this.props;
        const url = match.url.replace(/\/+$/, "");
        this.setScrollPositionFor(match.params.routeId);
        history.push(`${url}/${assignmentId}`);
    };

    onVisitButtonClicked = () => {
        const {match} = this.props;
        this.setScrollPositionFor(match.params.routeId);
    };

    setScrollPositionFor(routeId) {
        console.log("Setting scroll position for routeId: " + routeId);
        window.assignmentsScrollPos = {routeId, scrollY: window.scrollY};
    }

    getScrollPositionOrClear(routeId) {
        const lastPos = window.assignmentsScrollPos;
        if (!!lastPos && lastPos.routeId === routeId && lastPos.scrollY > 0) {
            return lastPos.scrollY;
        }
        window.assignmentsScrollPos = undefined;
        return undefined;
    }

    restoreScrollPosition() {
        const {match} = this.props;
        const lastYPos = this.getScrollPositionOrClear(match.params.routeId);
        if (!!lastYPos) {
            window.requestAnimationFrame(() => {
                window.scrollTo(0, lastYPos)
            })
        }
    }
}

export default withUserContext(Assignments);


export const parseAssignments = result => {
    result = result.listItems;
    return result.map(a => parseAssignment(a));
};

export const parseAssignment = a => ({
    id: a.id,
    name: a.customer.name,
    address: a.address,
    finished: !!a.finishedAt,
    visits: a.assignmentVisits || [],
    previouslyVisitedAt: a.previouslyVisitedAt,
    routeName: `${a.route.externalRouteId} - ${a.route.taskType.jobType}`
});
