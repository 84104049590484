import React, {Component} from 'react';
import PropTypes from 'prop-types';
import style from '../../Styles.module.scss';
import {ErrorMessage, LongPressableLoadingBtn} from "../../components/small-cute-components";
import AdvancedVisit, {
    VISIT_ACTION_FINISHED,
    VISIT_TYPE_DOUBLE,
    VISIT_TYPE_HALF,
    VISIT_TYPE_ONE
} from "../../components/AdvancedVisit";
import Modal from "../../components/Modal.v2";
import clsx from "clsx";


class AssignmentListItemUI extends Component {

    state = {
        displayAlternativeVisits: false
    };

    render() {
        const {displayAlternativeVisits} = this.state;
        const {
            address,
            lastVisited,
            hasFinished,
            visits,
            onPlaceVisit,
            isLoading,
            errorMsg,
            onComponentClicked
        } = this.props;

        const visitedOrFinishedStyle = hasFinished ? style.AssignmentListItemFinished : (visits.length > 0 ? style.AssignmentListItemVisited : null);

        return (
            <li className={clsx('list-group-item', 'list-group-item-action', visitedOrFinishedStyle)}
                onClick={onComponentClicked}>

                <div id="assignmentItem" className={style.AssignmentListItemContent}>
                    <h4>{address}</h4>

                    <div style={{width: '110px', flexShrink: '0', marginLeft: 'auto', textAlign: 'center'}}>
                        <LongPressableLoadingBtn
                            isLoading={isLoading}
                            className={`btn btn-lg btn-outline-success`}
                            onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                onPlaceVisit(VISIT_ACTION_FINISHED, VISIT_TYPE_ONE);
                            }}
                            onLongPress={() => {
                                this.setState({displayAlternativeVisits: true})
                            }}
                        >Besøk</LongPressableLoadingBtn>
                    </div>
                </div>

                <div style={{margin: '1rem 0'}}>
                    {visits.map(v => resolveLabelForVisitType(v))}
                </div>

                <ErrorMessage msg={errorMsg} style={{fontSize: '1rem', marginTop: '1rem', marginBottom: '1rem'}}/>

                {!!lastVisited && (
                    <div style={{textAlign: 'left', fontSize: '1rem'}}><span>{lastVisited}</span></div>
                )}

                {displayAlternativeVisits &&
                    <Modal>
                        <AdvancedVisit title={address}
                                       onDismiss={() => this.setState({displayAlternativeVisits: false})}
                                       onAction={(actionType, visitType) => {
                                           this.setState({displayAlternativeVisits: false});
                                           onPlaceVisit(actionType, visitType);
                                       }}
                                       canUnregister={visits.length > 0}
                        />
                    </Modal>}
            </li>
        );
    }
}

const resolveLabelForVisitType = visit => {
    switch (visit.visitType) {
        case VISIT_TYPE_HALF:
            return renderVisitItem(visit.id, style.LabelYellow, "1/2g");
        case VISIT_TYPE_ONE:
            return renderVisitItem(visit.id, style.LabelGreen, "1g");
        case VISIT_TYPE_DOUBLE:
            return renderVisitItem(visit.id, style.LabelLightBlue, "2g");
        default:
            return visit.numberOfHours ? renderVisitItem(visit.id, style.LabelLightBlue, visit.numberOfHours + "t") : null;
    }

    function renderVisitItem(id, labelStyle, visitElement) {
        return (<span key={id} className={`${style.Label} ${labelStyle}`}>{visitElement}</span>);
    }
};

AssignmentListItemUI.propTypes = {
    address: PropTypes.string.isRequired,
    lastVisited: PropTypes.string,
    hasFinished: PropTypes.bool.isRequired,
    visits: PropTypes.array.isRequired,
    onPlaceVisit: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    errorMsg: PropTypes.string,
    onComponentClicked: PropTypes.func.isRequired
};

export default AssignmentListItemUI;
