import React, {Component} from 'react';
import PropTypes from 'prop-types';
import style from '../Styles.module.scss';

class Modal extends Component {
    state = {
        hidden: false
    };
    
    close = () => {
        const { onModalClosed } = this.props;
        this.setState({hidden: true}, () => onModalClosed && onModalClosed());
    };
    
    accept = () => {
        const { onModalAccepted } = this.props;
        this.setState({hidden: true}, () => onModalAccepted && onModalAccepted());
    };
    
    render() {
        const { title, text, closeBtnTitle, acceptBtnTitle } = this.props;
        const { hidden } = this.state;
        
        return (
            hidden ? null :
            <div className={style.Modal}>
                <div className={style.ModalInner}>
                    <h5>{title}</h5>
                    <hr />
                    <p>{text}</p>
                    {closeBtnTitle && acceptBtnTitle ? this.btnGroup() : this.closeBtn()}
                </div>
            </div>
        );
    }
    
    closeBtn = () => {
        let { closeBtnTitle } = this.props;
        if (!closeBtnTitle) {
            closeBtnTitle = 'Lukk';
        }
        
        return <button className="btn btn-secondary"
                       style={{display: 'block', margin: '0 auto'}}
                       onClick={this.close}>{closeBtnTitle}</button>
    };
    
    btnGroup = () => {
        const { closeBtnTitle, acceptBtnTitle } = this.props;
        return (
            <div style={{display: 'block'}}>
                <button className="btn btn-secondary"
                        style={{display: 'inline-block', marginLeft: '.5rem', marginRight: '.5rem'}}
                        onClick={this.close}>{closeBtnTitle}</button>
                <button className="btn btn-primary"
                        style={{display: 'inline-block', marginLeft: '.5rem', marginRight: '.5rem'}}
                        onClick={this.accept}>{acceptBtnTitle}</button>
            </div>
            
        );
    };
}

Modal.propTypes = {
    title: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    
    closeBtnTitle: PropTypes.string,
    acceptBtnTitle: PropTypes.string,
    onModalClosed: PropTypes.func,
    onModalAccepted: PropTypes.func
};

export default Modal;
