import React, {Component} from "react";
import Async from "../../components/Async";
import PropTypes from "prop-types";
import {WarningMessage} from "../../components/small-cute-components";
import JobList from "./JobList";
import moment from "moment";

export class Jobs extends Component {

    render() {
        const {assignmentId} = this.props;

        return (
            <div>
                <Async url={`/api/v2/jobs?assignmentId=${assignmentId}&index=0&limit=5`} render={
                    ({result}) => {
                        if (result) {
                            console.log("result: ", result);
                            let jobs = Jobs.parseJobs(result);
                            console.log("jobs: ", jobs);
                            return jobs.length > 0 ? this.renderJobs(jobs) :
                                <WarningMessage msg="Fant ingen jobber."/>;

                        }
                        return null;
                    }
                }/>
            </div>
        );
    }

    static parseJobs(result) {
        result = result.listItems;

        return result.map(job => {
            const task = this.renderTask(job.task);

            return {
                id: job.id,
                task: task,
                priceType: this.parsePriceType(job),
                unitAmount: job.unitAmount,
                numberOfHours: job.hoursUsed,
                numberOfVisits: job.visits,
                performedDate: this.parseTimeStamp(job),
                performedByUser: this.parsePerformedBy(job.performedByUser)
            }
        });
    }

    renderJobs = (jobs) => {
        return (
            <div className="list-group">
                <JobList jobs={jobs}/>
            </div>
        );
    };

    static renderTask(task) {
        if (task) {
            return {
                id: task.id,
                taskType: {
                    id: task.taskType.id,
                    jobType: task.taskType.jobType
                },
                priceTypeValue: task.priceTypeValue
            }
        }
        return null;
    }

    static parseTimeStamp(job) {
        let date = new Date(job.performedDate);

        return moment(date).format('DD.MM.YY HH:mm');
    }

    static parsePerformedBy(performedBy) {
        if (performedBy) {
            return {
                id: performedBy.id,
                firstName: performedBy.firstName,
                lastName: performedBy.lastName
            }
        }
        return null;
    }

    static parsePriceType(job) {
        if (job.task == null || job.task.priceTypeValue == null) {
            return "Ikke satt";
        }

        return job.task.priceTypeValue;
    }
}

Jobs.propTypes = {
    assignmentId: PropTypes.number.isRequired,
};

export default Jobs;
