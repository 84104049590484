import React from "react";
import {Link} from "react-router-dom";
import {withUserContext} from "../../components/UserContext";
import styles from "../../Styles.module.scss";

class Home extends React.Component {

    render() {
        const {user, clearUser} = this.props;
        return (
            <div>
                <h3>Hei {user.fullName}</h3>

                <div className={`${styles.listItemsBlack} list-group`}>
                    <Link className="list-group-item list-group-item-action" to={`/myroutes`}>Mine ruter</Link>
                    <Link className="list-group-item list-group-item-action" to={`/allroutes`}>Alle ruter</Link>
                    <b><a className="list-group-item list-group-item-action" href={`/chauffeurapp`}>Ny app</a></b>
                    <button className="list-group-item list-group-item-action" type={`button`} onClick={clearUser}>Logg ut</button>
                </div>
            </div>
        );
    }
}

export default withUserContext(Home);
