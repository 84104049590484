import React from 'react';
import {withUserContext} from "../../components/UserContext";
import {Redirect} from "react-router";
import LoginUI from "./LoginUI";
import RequestComponent from "../../components/RequestComponent";
import {goFetch, makeCancelable} from "../../utils/GoFetch";

class Login extends RequestComponent {

    state = { loading: false, errorMsg: '' };

    performLogin = (username, password) => {

        const { setUser } = this.props;

        this.setState({ loading: true, errorMsg: '' }, () => {
            this.request = makeCancelable(
                this.request = goFetch('/api/v2/authentication', '', 'POST', {
                    username: username,
                    password: password,
                    userAgent: 'webapp'
                })
            );

            this.request.promise.then(
                data => setUser(data.accessToken, data.refreshToken, data.expiresInSeconds, data.userId, data.fullName),
                error => this.handleLoginError(error)
            );
        });
    };

    handleLoginError = (e) => {
        this.setState({
            loading: false,
            errorMsg: e.errorMsg
        });
    };

    resetPassword = () => {
        const port = window.location.hostname === 'localhost' ? "8080" : "";
        const url = window.location.protocol + "//" + window.location.hostname + ":" + port + "/recover";
        return window.location.href = url
    };

    render() {
        const { isAuthenticated } = this.props;
        const { loading, errorMsg } = this.state;

        if (isAuthenticated) {
            return <Redirect to="/" />
        }
        return (
            <LoginUI isLoading={loading} errorMsg={errorMsg} performLogin={this.performLogin} resetPassword={this.resetPassword}/>
        );
    }
}

export default withUserContext(Login);
