import React, {useState} from 'react';
import PropTypes from 'prop-types';

const INITIAL_STATE = {
    longPressTimer: null, didLongPress: false
};

const LongPressButton = ({onClick, onLongPress, longPressMs = 700, ...rest}) => {

    // window.oncontextmenu = () => false;

    const [state, setState] = useState(INITIAL_STATE);

    const onPress = (evt) => {
        if (!state.longPressTimer) {
            setState({
                ...state,
                longPressTimer: setTimeout(() => {
                    onLongPress && onLongPress();
                    setState({...state, didLongPress: true})
                }, longPressMs)
            })
        }
    };

    const onRelease = (evt) => {

        if (!!state.longPressTimer) {
            clearTimeout(state.longPressTimer);
            setState({...state, longPressTimer: null})
        }
    };

    const onClickWrapper = (evt) => {
        if (state.didLongPress) {
            // DEV-NOTE discard on click!
            evt.preventDefault();
            evt.stopPropagation();
            setState(INITIAL_STATE);
        } else {
            onClick && onClick(evt);
        }
    };

    return (
        <button type="button"
                id="visitButton"
                onTouchStart={onPress}
                onMouseDown={onPress}
                onTouchEnd={onRelease}
                onMouseUp={onRelease}
                onMouseLeave={onRelease}
                onClick={onClickWrapper}
                {...rest}>
            {rest.children}
        </button>
    );
};

LongPressButton.propTypes = {
    onClick: PropTypes.func,
    onLongPress: PropTypes.func,
    longPressMs: PropTypes.number,
};

export default LongPressButton;
