import React, {Component} from 'react';
import PropTypes from 'prop-types';
import './routes.scss';
import Async from "../../components/Async";
import {WarningMessage} from "../../components/small-cute-components";
import Breadcrumb from "../../components/Breadcrumb";
import RouteListItem from "./RouteListItem";
import {makeUnique} from "../../utils/Utils";

export default class Routes extends Component {

    render() {
        const {config, match} = this.props;

        return (
            <div className="user-select--none">
                <Breadcrumb match={match}/>

                <Async url={config.urlToRoutes} render={
                    ({result}) => {
                        if (result) {
                            let routes = Routes.parseRoutes(result, config.showRouteOwner);
                            return routes.length > 0 ? this.renderRoutes(routes) :
                                <WarningMessage msg="Fant ingen ruter."/>;
                        }
                        return null;
                    }
                }/>
            </div>
        );
    }

    static parseRoutes(result, showRouteOwner) {
        result = result.listItems;

        return result.map(r => {

            const jobType = r.taskType != null && r.taskType.jobType != null ? r.taskType?.jobType : "Oppgavetype ikke definert";
            const routeOwnerName = r.user != null ? `${r.user?.firstName} ${r.user?.lastName}` : "Ingen ruteeier definert";

            return {
                id: r.id,
                owner: showRouteOwner ? routeOwnerName : null,
                name: `${r.externalRouteId} - ${jobType}`,
                startedAt: r.routeTripDto ? r.routeTripDto.startedDate : null,
                finishedAt: r.routeTripDto ? r.routeTripDto.finishedDate : null,
                displayText: r.displayText,
                hasAssignments: r.hasAssignments
            }
        });
    }

    renderRoutes = (routes) => {
        const {match} = this.props;
        return (
            <div className="list-group">
                {routes.map(route =>
                    <RouteListItem key={makeUnique(route.id)} path={`${match.path.replace(/\/+$/, "")}/${route.id}`}
                                   route={route}/>
                )}
            </div>
        );
    };
}

Routes.propTypes = {
    config: PropTypes.object.isRequired
};
