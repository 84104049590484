import React from 'react';

class RequestComponent extends React.Component {

    request = null;

    componentWillUnmount() {
        if (this.request) {
            this.request.cancel();
        }
    }
    
    render() {
        return null;
    }
}

export default RequestComponent;