import React from 'react';
import styles from "../Styles.module.scss";
import LongPressButton from "./LongPressButton";

export const Loading = ({style}) => (
    <div style={style} className={`${styles.Loading} spinner-border`}>
        <span className="sr-only">Loading...</span>
    </div>
);

export const WarningMessage = ({msg, style}) => (
    !!msg && msg !== '' ? <div style={style} className="alert alert-warning margin--medium" role="alert">{msg}</div> : null
);

export const ErrorMessage = ({msg, style}) => (
    !!msg && msg !== '' ? <div style={style} className="alert alert-danger" role="alert">{msg}</div> : null
);

export const If = ({condition, children}) => (
    !!condition ? <React.Fragment>{children}</React.Fragment> : null
);

export const LoadingBtn = ({isLoading, disabled, onClick, ...props}) => (
    <div style={{textAlign: 'center'}}>
        {isLoading ? <Loading style={{display: 'inline-block', width: '40px', height: '40px', margin: '0', borderWidth: '.15em'}} /> :
            <button type="button" disabled={disabled} onClick={onClick} {...props}>
                {props.children}
            </button>
        }
    </div>
);

export const LongPressableLoadingBtn = ({isLoading, disabled, onClick, ...props}) => (
    <div style={{textAlign: 'center'}}>
        {isLoading ? <Loading style={{display: 'inline-block', width: '40px', height: '40px', margin: '0', borderWidth: '.15em'}} /> :
            <LongPressButton disabled={disabled} onClick={onClick} {...props}>
                {props.children}
            </LongPressButton>
        }
    </div>
);

export const Subheading = props => (
    <h4 style={{paddingLeft: '1.25rem', paddingRight: '1.25rem'}} {...props}>{props.children}</h4>
);