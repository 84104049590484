import React from 'react';
import TasksUI from "./TasksUI";
import {withUserContext} from "../../components/UserContext";
import Async from "../../components/Async";
import Breadcrumb from "../../components/Breadcrumb";
import {WarningMessage} from "../../components/small-cute-components";

class Tasks extends React.Component {

    render() {
        const {match} = this.props;
        let routeId = match.params.routeId;
        let assignmentId = match.params.assignmentId;

        return (
            <div>
                <Breadcrumb match={match}/>

                <Async url={`/api/v2/assignments/${assignmentId}/tasks?excludeIfTractorJobType=true`} render={
                    ({result}) => {
                        if (result) {
                            let allTasks = Tasks.parseTasks(result);
                            const tasksForTaskRegistration = Tasks.filterTasksForTaskRegistration(allTasks);

                            if (allTasks.length === 0) {
                                return <WarningMessage msg="Fant ingen detaljer på valgt plass."/>;
                            }

                            const data = allTasks[0];
                            let fileLinks = [];
                            if (!!data.fileLinks) {
                                fileLinks = data.fileLinks.listItems || [];
                            }

                            return <TasksUI tasks={tasksForTaskRegistration}
                                            routeId={Number(routeId)}
                                            assignmentId={Number(assignmentId)}
                                            assignmentAddress={data.assignmentAddress}
                                            assignmentDescription={data.assignmentDescription}
                                            maximumAllowedSnowDepth={data.maximumAllowedSnowDepth}
                                            performedByTime={data.performedByTime}
                                            fileLinks={fileLinks}
                            />
                        }
                        return null;
                    }
                }/>
            </div>
        );
    }

    static parseTasks(result) {
        result = result.listItems;

        return result.map(t => {

            return {
                id: t.taskType.id,
                name: t.taskType.jobType,
                assignmentAddress: t.assignmentAddress,
                assignmentDescription: t.assignmentDescription,
                fileLinks: t.fileLinks,
                maximumAllowedSnowDepth: t.maximumAllowedSnowDepth,
                performedByTime: t.performedByTime,
                useForTaskRegistration: t.useForTaskRegistration
            }
        })
    }

    static filterTasksForTaskRegistration(tasks) {
        return tasks.filter(t => t.useForTaskRegistration === true);
    }
}

export default withUserContext(Tasks);
