import React from 'react';
import {Route, Switch} from "react-router-dom";
import Home from "./pages/home/Home";
import Header from "./components/Header";
import {ProtectedRoute} from "./components/UserContext";
import Login from "./pages/login/Login";
import MyRoutes from "./pages/routes/MyRoutes";
import Assignments from "./pages/assignments/Assignments";
import Tasks from "./pages/tasks/Tasks";
import AllRoutes from "./pages/routes/AllRoutes";
import {version} from "../package.json";

export default class App extends React.Component {
    
    

    render() {
        return (
            <div className="app-container">
                
                <Header />

                <div className="content">
                    <Switch>
                        <ProtectedRoute exact path="/" component={Home}/>
                        
                        <ProtectedRoute exact path={`/myroutes`} component={MyRoutes} />
                        <ProtectedRoute exact path={`/myroutes/:routeId`} component={Assignments} />
                        <ProtectedRoute exact path={`/myroutes/:routeId/:assignmentId`} component={Tasks} />
                        
                        <ProtectedRoute exact path={`/allroutes`} component={AllRoutes} />
                        <ProtectedRoute exact path={`/allroutes/:routeId`} component={Assignments} />
                        <ProtectedRoute exact path={`/allroutes/:routeId/:assignmentId`} component={Tasks} />
                        
                        <Route path={`/login`} component={Login}/>
                        <Route path={`/:site`} component={PathNotFound}/>
                    </Switch>
                </div>

                <div className="footer">
                    <p className="margin_bottom--none">© 2020 Snøservice AS</p>
                    <span className="app-version float-right">v{version}</span>
                    <div className="clearfix" />
                </div>
            </div>
        );
    }
}


const PathNotFound = ({match}) => (
    <div>
        <h3>Siden '{match.params.site}' ble ikke funnet</h3>
        <Route path={`${match.path}/:site`} component={PathNotFound}/>
    </div>
);
