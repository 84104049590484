import React from 'react';
import logo2 from "../assets/img/logo2.png";
import {withUserContext} from "./UserContext";
import {Link} from "react-router-dom";


const Header = (props) => (
    <header className={props.isAuthenticated ? 'header-slim' : 'header'}>
        <div>
            {props.isAuthenticated ? <Link to="/"><img src={logo2} alt="logo" /></Link> : <img src={logo2} alt="logo" />}
        </div>
    </header>
);

export default withUserContext(Header);
