import React from 'react';
import Routes from "./MyRoutes";

const AllRoutes = props => {
    return (
        <div>
            <Routes config={{urlToRoutes: '/api/v2/routes?offset=0&limit=100', showRouteOwner: true}} {...props} />
        </div>
    );
};

export default AllRoutes;
