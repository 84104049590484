import React, {Component} from 'react';
import PropTypes from 'prop-types';
import * as ReactDOM from "react-dom";

class Dropdown extends Component {

    state = {
        isOpen: false,
        selectedItem: null
    };

    componentDidMount() {
        document.addEventListener('click', this.dismissIfClickOutside, true);
        document.addEventListener('touchstart', this.dismissIfClickOutside, true);
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.dismissIfClickOutside, true);
        document.removeEventListener('touchstart', this.dismissIfClickOutside, true);
    }

    dismissIfClickOutside = evt => {
        const {isOpen} = this.state;
        if (isOpen) {
            const self = ReactDOM.findDOMNode(this);
            if (self && !self.contains(evt.target)) {
                this.setState({isOpen: false});
            }
        }
    };

    onToggle = () => {
        this.setState({isOpen: !this.state.isOpen});
    };

    onItemSelect = (evt) => {
        evt.preventDefault();

        const {items, onItemSelected} = this.props;
        let key = evt.target.getAttribute('data-key');
        let item = items.find(i => String(i.id) === key);

        this.setState({
            isOpen: false,
            selectedItem: item
        });

        onItemSelected(item);
    };

    render() {
        const {title, items, style} = this.props;
        const {isOpen, selectedItem} = this.state;

        return (
            <div className="dropdown" style={style}>

                <button className="btn btn-outline-primary dropdown-toggle" type="button" onClick={this.onToggle}
                        style={{
                            width: '100%',
                            fontSize: '1.6rem',
                            textOverflow: 'ellipsis',
                            overflow: 'hidden',
                            whiteSpace: 'normal',
                            wordWrap: 'break-word'
                        }}>
                   {selectedItem ? selectedItem.name : title}
                </button>

                <div className="dropdown-menu"
                     style={{
                         display: isOpen ? 'block' : 'none',
                         width: '100%',
                         fontSize: '1.6rem',
                         marginTop: '0.2rem',
                         margin: 'auto',
                         textAlign: 'left'
                     }}>
                    {items.map(i => <button key={i.id} data-key={i.id}
                                            onClick={this.onItemSelect}
                                            style={{
                                                whiteSpace: 'normal',
                                                wordWrap: 'break-word',
                                                width: '90%',
                                            }}
                                            className="dropdown-item" type="button">
                        {i.name}</button>)}
                </div>
            </div>
        );
    }
}

Dropdown.propTypes = {
    title: PropTypes.string.isRequired,
    items: PropTypes.array.isRequired,
    onItemSelected: PropTypes.func.isRequired,
    style: PropTypes.object
};

export default Dropdown;
